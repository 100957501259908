@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

a:hover {
  color: #000;
}

/* HEADER */

.ant-btn.ant-btn-lg.ant-btn-icon-only .anticon {
  font-size: 25px !important;
}

.ant-btn.ant-btn-lg {
  border: 2px solid rgb(240, 233, 233);
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #aeb0b3;
}

.noti-bell {
  font-size: 25px !important;
}

.noti-bell:hover {
  color: #000 !important;
}

.unReadNumber {
  font-size: 15px;
}

.role {
  border: 2px solid rgb(240, 233, 233);
  border-radius: 10px;
  margin-right: 48px;
  padding: 0 12px;
  height: 55px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .marquee {
    display: none;
  }
}

.bell {
  font-size: 15px !important;
  padding: 6px;
  align-items: baseline !important;
  border-radius: 50px;
  margin-top: 8px;
  border: 1px solid #000;
  width: 30px !important;
  height: 30px !important;
}

.see-all {
  color: #1677ff;
  text-align: center;
  font-weight: 600;
  margin-top: 4px;
}

.close-btn {
  font-size: 15px !important;
  align-items: baseline !important;
  margin-top: 8px;
  width: 18px;
  height: 16px;
  color: #1677ff;
}

.close-btn:hover {
  scale: 1.4;
  color: #1677ff;
}

:where(.css-dev-only-do-not-override-qgg3xn) a:hover {
  color: 0 !important;
}

/* CUSTOM ANTD */

.ant-table-thead th {
  background-color: #fd9900 !important;
  border-color: #fd9900 !important;
  color: white !important;
  font-size: 15px;
}

.ant-table-cell {
  padding: 8px 8px !important;
}

.ant-table-cell::before {
  background: none !important;
}

td.ant-table-cell {
  padding: 10px !important;
}

.action-button {
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  outline: none;
}

.ant-pagination-item {
  font-weight: 600 !important;
}

.ant-pagination-item-active {
  border: none !important;
  background-color: #fd9900 !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-row {
  margin-top: 10px;
}

.add-btn {
  background-color: #1677ff;
  margin: -20px 0px 20px;
  padding: -1px 1.5rem;
}

.add-btn2 {
  background-color: #1677ff;
}

.add-user-btn {
  margin: -20px 0px 20px;
  padding: -1px 1.5rem;
}

.btn-del-all {
  color: white;
  /* background-color: rgb(239 68 68); */
  margin: -20px 0px 20px;
  padding: -1px 1.5rem;
}

.btn-excel {
  color: white;
  background-color: #16a34a;
  margin: -20px 0px 20px;
  padding: -1px 1.5rem;
}

.add-reset {
  color: white;
  margin: -20px 0px 20px;
  padding: -1px 1.5rem;
}

.add-reset:hover {
  background-color: rgb(107 114 128) !important;
}

.btn-excel:hover {
  color: #fff !important;
  border-color: rgb(22 163 74) !important;
}

.btn-del {
  border: 0;
}

.btn-del:hover {
  background-color: rgb(226, 44, 44);
}

.form-search {
  margin: -20px 0px 20px;
  padding: -1px 1.5rem;
}

.ant-picker-ok {
  background-color: #1677ff;
  height: 24px;
  border-radius: 5px;
  line-height: 10px;
}

/* MODAL */

.ant-modal-title {
  font-size: 20px !important;
  color: #fd9900 !important;
}

.modal-info {
  width: auto;
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: rgb(245 245 245);
  border-radius: 5px;
  font-size: 16px;
  padding-left: 10px;
  margin-top: 7px;
}

.modal-height {
  width: auto;
  height: initial;
  display: flex;
  /* căn giữa theo chiều ngang */
  align-items: center;
  background-color: rgb(245 245 245);
  border-radius: 5px;
  font-size: 16px;
  padding-left: 10px;
  margin-top: 7px;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: white;
}

.btn-note:hover {
  color: #222 !important;
}
.custom-btn-class button:first-child {
  background-color: transparent;
  background-image: none;
}

.custom-btn-class button:last-child {
  background-color: rgb(239 68 68);
  color: white;
}

.custom-btn-class:hover button:last-child {
  color: white !important;
}

.custom-btn-class:hover button:first-child {
  border-color: #b9adad !important;
  color: #000 !important;
}

.ant-input-group-addon button {
  background-color: white !important;
}

.cancelButtonProps {
  color: #000 !important;
}

.cancelButtonProps:hover {
  border-color: #b9adad !important;
  color: #000 !important;
}

.noindex {
  color: red;
}

.long-text {
  display: block;
  color: #4096ff;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 
.description-text {
  display: block;
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */

/* CSS cho index màu xanh */
.index {
  color: green;
}

/* RESPONSIVE */

@media screen and (max-width: 1024px) {
  .long-text {
    width: 100px;
  }
  th.ant-table-cell {
    font-size: 14px;
  }
}

.ant-layout-content {
  overflow: hidden;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 25s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

td.antd-cellCustom {
  overflow: auto;
}

td.antd-cellCustom::-webkit-scrollbar {
  width: 4px;
  height: 0.6rem;
  border-radius: 50px;
}

td.antd-cellCustom::-webkit-scrollbar-track {
  background: #f1f1f1;
}

td.antd-cellCustom::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

td.antd-cellCustom::-webkit-scrollbar-thumb:hover {
  background: #555;
}

td.antd-cellCustom .ant-tag {
  margin-bottom: 4px;
}

.processing-index {
  color: white;
  margin: -20px 0px 20px;
  padding: -1px 1.5rem;
  cursor: not-allowed;
  background-color: #ffd782;
}

.processing-index:focus-visible{
  outline: none !important;
}
